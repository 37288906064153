import * as React from "react";
import Newword from "../components/Newword";
import "./new.css";

const New = () => {
  return (
    <div className="new-box">
      <Newword />
    </div>
  );
};

export default New;
